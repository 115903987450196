"use client";

import {useTranslation} from "ni18n";
/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import React, {ReactElement, useState} from "react";
import useServicesConstants, {
  LocationForServices,
  ServicesMode,
} from "src/constants/ServicesConstants";
import {useV5ImgSrc} from "src/useV5ImgSrc";

import {useWindowSize} from "../_common/Carbon";
import {LazyComp} from "../_common/LazyComp";
import MobileServiceCards from "./MobileServiceCards";
import {Btn} from "./V5Comp";
import {useIsMobile} from "src/utils/isMobile";

export type ServicesProps = {
  locations: LocationForServices[];
  modes?: ServicesMode[];
};

const Services: React.FC<ServicesProps> = ({modes, locations}) => {
  const i18n = useTranslation();
  const {modes: innerModes} = useServicesConstants({locations});

  if (!modes) {
    modes = innerModes;
  }

  const {width} = useWindowSize();
  const isMobile = useIsMobile();

  const [activeIndex, setActiveIndex] = useState(0);

  const activeMode = modes[activeIndex];

  const toggleIndex = (i: number) => () => {
    setActiveIndex(i);
  };

  const imgSrc = useV5ImgSrc(activeMode?.bgImage || "");

  return (
    <div>
      {isMobile ? (
        <MobileServiceCards modes={modes} />
      ) : (
        <section className="df pr3 fdcr-sm contrast-tbt" aria-labelledby="services-heading">
          <h2 id="services-heading" className="visually-hidden">
            {i18n.t("Services")}
          </h2>
          <ul
            aria-hidden
            className="fx2 fx3-md p4 pl8 pl2-sm df fdc fs60 fs50-xl fs48-lg fs38-md font-csb lh15 t-200 oh dn-sm contrast-tbr"
            style={{
              ...(activeMode.bgImage ? {backgroundImage: `url(${imgSrc})`} : {}),
              backgroundSize: "cover",
              backgroundRepeat: "none",
              backgroundColor: activeMode.panelColor,
              color: activeMode.listColor,
              letterSpacing: -1,
            }}
          >
            {activeMode.commonReasons?.map((item, i) => (
              <li key={`common-reason-${i}`} className="wsnw">
                {item}
              </li>
            ))}
          </ul>
          <div className="fx3 bg-gray50" style={{paddingTop: 100 - modes.length * 20}}>
            <div className="pt36 pt16-xl pt14-lg pb4-sm pl20 pl16-xl pl10-lg pl6-md pl3-sm t-200 minh140-sm w100p">
              {modes.map((mode, i) => {
                const isActive = i === activeIndex;
                return (
                  <div
                    key={mode.header}
                    className="mb16 mb10-md mb6-sm df"
                    onMouseEnter={toggleIndex(i)}
                    onFocus={toggleIndex(i)}
                  >
                    <span
                      className="br5 t-200 mr8 mr4-md mt3 mt2-lg"
                      style={{
                        height: 20,
                        width: 20,
                        backgroundColor: mode.dotColor || mode.panelColor,
                        opacity: isActive ? 1 : 0,
                      }}
                    />
                    <div>
                      <h3 className="fs36 fs30-lg mb1" tabIndex={0}>
                        {mode.header}
                      </h3>
                      <div
                        className="t-200"
                        style={{
                          // @ts-expect-error TS2532: Object is possibly 'undefined'.
                          height: isActive ? (width < 752 ? "9.5rem" : "8rem") : 0,
                          opacity: isActive ? 1 : 0,
                        }}
                      >
                        <strong className="dib font-ir mb4 mb2-sm">{mode.subtext}</strong>
                        <ul className="visually-hidden">
                          {mode.commonReasons?.map((item, i) => (
                            <li key={`common-reason-${i}`}>{item}</li>
                          ))}
                        </ul>

                        <div className="df fdc-sm">
                          {/* @ts-expect-error TS2532: Object is possibly 'undefined'. */}
                          {mode.buttons.map((props, i) => (
                            <Btn
                              className="pos-r mr2 mr0-sm mb6-sm fs14-f fs12-f-sm"
                              key={`${props.href}${i}`}
                              {...props}
                            />
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </section>
      )}
    </div>
  );
};

const ServicesWrapper = (props: ServicesProps): ReactElement => (
  <LazyComp>
    <Services {...props} />
  </LazyComp>
);

export default ServicesWrapper;
